import React from 'react';

import Icon from './Icon';

export default function LightThemeIcon({ className = '' }: {className?: string;}): JSX.Element {
  return <Icon
    text="Light theme">
   <svg className={className} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5 16C12.5 17.0609 12.9214 18.0783 13.6716 18.8284C14.4217 19.5786 15.4391 20 16.5 20C17.5609 20 18.5783 19.5786 19.3284 18.8284C20.0786 18.0783 20.5 17.0609 20.5 16C20.5 14.9391 20.0786 13.9217 19.3284 13.1716C18.5783 12.4214 17.5609 12 16.5 12C15.4391 12 14.4217 12.4214 13.6716 13.1716C12.9214 13.9217 12.5 14.9391 12.5 16Z" fill="#2F3034" stroke="#2F3034" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
<path d="M16.5 4.5V8.5" stroke="#2F3034" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
<path d="M16.5 23.5V27.5" stroke="#2F3034" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
<path d="M8.36816 7.86719L11.1972 10.6952" stroke="#2F3034" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
<path d="M21.8027 21.3047L24.6317 24.1337" stroke="#2F3034" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
<path d="M5 16H9" stroke="#2F3034" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
<path d="M24 16H28" stroke="#2F3034" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
<path d="M8.36816 24.1337L11.1972 21.3047" stroke="#2F3034" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
<path d="M21.8027 10.6952L24.6317 7.86719" stroke="#2F3034" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>
  </Icon>;
}
